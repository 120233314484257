import DpGridMixin from 'src/core/components/DpGrid/DpGridMixin.js'
import DpGridPageMixin from 'src/core/components/DpGrid/DpGridPageMixin.js'
import DpGridAcoes from 'src/core/components/DpGrid/DpGridAcoes.vue'
import DpGrid from 'src/core/components/DpGrid/DpGrid2.vue'
import DpGridStore from 'src/store/dp-grid/'
import vTopFilters from 'src/core/filters/vTopFilters'
import MarcaModel from 'src/model/veiculo/MarcaModel'

export default {
  components: { DpGrid, DpGridAcoes },
  mixins: [DpGridMixin, DpGridPageMixin, vTopFilters],
  data () {
    return {
      modelPadrao: new MarcaModel()
    }
  },
  meta () {
    return {
      title: 'Marcas',
      meta: [
        { 'name': 'robots', 'content': 'noindex' }
      ]
    }
  },
  mounted () {
    if (!this.$store.hasModule('dp-grid')) this.$store.registerModule('dp-grid', DpGridStore)
  }
}
